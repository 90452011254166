/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'box-arrow-up-left': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M7.364 3.5a.5.5 0 01.5-.5H14.5A1.5 1.5 0 0116 4.5v10a1.5 1.5 0 01-1.5 1.5h-10A1.5 1.5 0 013 14.5V7.864a.5.5 0 111 0V14.5a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-10a.5.5 0 00-.5-.5H7.864a.5.5 0 01-.5-.5"/><path pid="1" fill-rule="evenodd" d="M0 .5A.5.5 0 01.5 0h5a.5.5 0 010 1H1.707l8.147 8.146a.5.5 0 01-.708.708L1 1.707V5.5a.5.5 0 01-1 0z"/>',
    },
});
